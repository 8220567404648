
import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import graph from './helper/graph';
import { useOutsideBlur, getNestedValue } from './helper/utils';
import { useSession } from './hooks/session';

interface ifSwingsDistribution {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account?:any;
    metric:any;
    selected:boolean;
    visible:boolean;
    subtitle?:string;
}
const SwingsDistribution = ({ dateStart, dateEnd, equipment, environment, account, metric, selected, visible, subtitle }:ifSwingsDistribution) => {
    const [swingData, setSwingData] = useState<any>(null);
    const [swingDataLoading, setSwingDataLoading] = useState<boolean>(true);
    const { getAccessToken } = useSession();
    const [countType, setCountType] = useState<string>("all");
    const highchartsRef = useRef(null);
    const defaultFields = [
        metric.field
    ];

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd && visible) {
            setSwingDataLoading(true);
            getData();
        }
    }, [dateStart, dateEnd, equipment.length, environment.length, account, visible]);

    useEffect(() => {
        if(swingData) {
            setSwingDataLoading(false);
            renderChart();
        }
    }, [swingData]);

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd) {
            setSwingDataLoading(true);
            getData();
        }
    }, [countType]);

    const getMetric = (swing:any):number => {
        return Number(getNestedValue(swing, metric.field));
    }

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : (() => {
                if(countType == "all") {
                    return `/swings/stats/ranges`;
                } else if(countType == "equipment") {
                    return `/swings/stats/ranges/equipment`;
                } else if(countType == "environment") {
                    return `/swings/stats/ranges/environments`;
                }

                return "";
            })(),
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account,
                "fields" : defaultFields
            }
        });

        setSwingData(swingsResponse[metric.key]);
    }


    const renderChart = ():void => {
        const chartData:Highcharts.Options = {
            chart: {
                type: 'column',
                animation: false
            },
            title: {
                text: `${metric.label} Distribution`,
                align: 'left'
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                type: 'category',
                ordinal: true,
                categories: swingData.map((rangeBucket:any) => {
                    return rangeBucket.range + (metric.unit ? `${metric.unit}` : "");
                })
            },
            yAxis: {
                title: {
                    text: "Number of Swings"
                },
                labels: {
                    format: `{value}`
                },
                max : metric.yAxis.max ?? null
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                column: {
                    stacking: "normal"
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                formatter: function() {
                    let tooltip:string = `${metric.label}: <b>${this.key}</b><br />`;
                    if(countType !== "all") {
                        if(countType === "equipment") {
                            tooltip += `Bat: <b>${(this.point as any).aggName}</b><br />`;
                        } else if(countType === "environment") {
                            tooltip += `Environment: <b>${(this.point as any).aggName}</b><br />`;
                        }
                        tooltip += `Swings: <b>${this.y?.toLocaleString()}</b><br />`;
                    }
                    tooltip += `Total Swings: <b>${this.total?.toLocaleString()}</b>`;
                    return tooltip;
                }
            },
            series: [
                ...getSeries()
            ],
            credits: {
                enabled: false
            },
            accessibility : {
                enabled: false
            }
        }

        if(subtitle) {
            chartData.subtitle = {
                text: subtitle,
                align: 'left'
            }
        }

        Highcharts.chart(highchartsRef.current!, chartData);
    }

    const getSeries = ():any[] => {
        const _equipment:string[] = [];
        const _environment:string[] = [];
        if(countType === "equipment") {
            swingData.forEach((bucket:any) => {
                bucket.equipment.forEach((equipment:any) => {
                    _equipment.push(equipment.name);
                });
            });
        }
        if(countType === "environment") {
            swingData.forEach((bucket:any) => {
                bucket.environment.forEach((environment:any) => {
                    _environment.push(environment.name);
                });
            });
        }
        const equipmentList:string[] = [...new Set(_equipment)];
        const environmentList:string[] = [...new Set(_environment)];

        if(countType === "all") {
            return [{
                "name" : `${metric.label}`,
                "id" : `${metric.key}-distribution`,
                "type" : "column",
                "color" : metric.colors.equipment[0],
                "borderColor" : "var(--color-surface-600)",
                "data" : swingData.map((rangeBucket:any, iteration:number) => {
                    return {
                        "x" : iteration,
                        "y" : rangeBucket.count
                    }
                })
            }];
        } else if(countType === "equipment") {
            const _series:any[] = [];

            equipmentList.forEach((equipment:string) => {
                _series.push({
                    "type" : "column",
                    "name" : equipment,
                    "id" : `${metric.key}-${equipment}`,
                    "color" : graph.colorAssignments(equipmentList, metric.colors.equipment)[equipment],
                    "borderColor" : "var(--color-surface-600)",
                    "data" : swingData.map((bucket:any, iteration:number) => {
                        const match:any = bucket.equipment.find((equip:any) => equipment === equip.name);
                        return {
                            "x" : iteration,
                            "y" : match ? match.count : 0,
                            "aggLabel" : "Equipment Swings",
                            "aggType" : "count",
                            "aggName" : match ? match.name : ""
                        }
                    })
                });
            });

            return _series;
        } else if(countType === "environment") {
            const _series:any[] = [];

            environmentList.forEach((environment:string) => {
                _series.push({
                    "type" : "column",
                    "name" : environment,
                    "id" : `${metric.key}-${environment}`,
                    "color" : graph.colorAssignments(environmentList, metric.colors.environment)[environment],
                    "borderColor" : "var(--color-surface-600)",
                    "data" : swingData.map((bucket:any, iteration:number) => {
                        const match:any = bucket.environment.find((env:any) => environment === env.name);
                        return {
                            "x" : iteration,
                            "y" : match ? match.count : 0,
                            "aggLabel" : "Environment Swings",
                            "aggType" : "count",
                            "aggName" : match ? match.name : ""
                        }
                    })
                });
            });

            return _series;
        }

        return [];
    }

    if(!swingData) {
        return (
            <div className={`graph-container ${!selected ? "hide" : ""}`}>
                <div className={`graph-loader`} />
            </div>
        );
    }

    return (
        <div className={`graph-container ${!selected ? "hide" : ""}`}>
            <div ref={highchartsRef}></div>
            <div className="graph-actions button-group">
                <button
                    className={countType === "all" ? "primary" : ""}
                    onClick={() => {
                        if(countType === "all") {
                            return;
                        }

                        setCountType("all");
                    }}
                >
                    All
                </button>
                <button
                    className={countType === "equipment" ? "primary" : ""}
                    onClick={() => {
                        if(countType === "equipment") {
                            return;
                        }

                        setCountType("equipment");
                    }}
                >
                    Bats
                </button>
                <button
                    className={countType === "environment" ? "primary" : ""}
                    onClick={() => {
                        if(countType === "environment") {
                            return;
                        }

                        setCountType("environment");
                    }}
                >
                    Environments
                </button>
            </div>
            <div className={`graph-loader ${swingDataLoading ? "show" : "hide"}`} />
        </div>
    );
}


export default SwingsDistribution;