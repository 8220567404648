import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import apiRequest from '../helper/ws';

interface ifState {
    accessToken:string|null;
    accountList:any[];
}

const initialState:ifState = {
    accessToken : sessionStorage.getItem("accessToken") ?? null,
    accountList : []
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAccessToken : (state:any, input:PayloadAction<string|null>) => {
            return {
                ...state,
                accessToken : input.payload
            }
        },
        setAccountList : (state:any, input:PayloadAction<any[]>) => {
            return {
                ...state,
                accountList : input.payload
            }
        }
    },
});

export const {
    setAccessToken,
    setAccountList
} = appSlice.actions;
export default appSlice.reducer;