
import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import graph from './helper/graph';
import { formatNumber } from './helper/utils';
import { useSession } from './hooks/session';

HighchartsMore(Highcharts);

interface ifSwingStatsByDay {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account?:any;
    metric:any;
    selected:boolean;
    visible:boolean;
    subtitle?:string;
    showEquipmentSplits:boolean;
}
const SwingStatsByDay = ({ dateStart, dateEnd, equipment, environment, account, metric, selected, visible, showEquipmentSplits=true, subtitle }:ifSwingStatsByDay) => {
    const { getAccessToken } = useSession();
    const [swingData, setSwingData] = useState<any>(null);
    const [swingDataLoading, setSwingDataLoading] = useState<boolean>(true);
    const [withEquipment, setWithEquipment] = useState<boolean>(false);
    const highchartsRef = useRef(null);
    const defaultFields = [
        "context.environment",
        "created",
        "equipment.name",
        metric.field
    ];
    const aggTypes = [
        "count",
        "avg",
        "max",
        "min"
    ];

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd && visible) {
            setSwingDataLoading(true);
            getData();
        }
    }, [dateStart, dateEnd, equipment.length, environment.length, account, withEquipment, visible]);

    useEffect(() => {
        if(swingData) {
            setSwingDataLoading(false);
            renderChart();
        }
    }, [swingData]);

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/swings/stats/daily${withEquipment ? "/equipment" : ""}`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account,
                "fields" : defaultFields,
                "avg" : metric.field,
                "max" : metric.field,
                "min" : metric.field
            }
        });

        // set the swingData and also create an incrementing value for each (x axis)
        setSwingData(swingsResponse);
    }

    const getUnit = (unit:string, aggType:string) => {
        if(aggType == "count") {
            return "";
        }

        return unit;
    }

    const renderChart = ():void => {
        const chartData:Highcharts.Options = {
            title: {
                text: `${metric.label} Daily Splits`,
                align: 'left'
            },
            xAxis: {
                //startOnTick: true,
                //endOnTick: true,
                showLastLabel: true,
                type: 'category',
                ordinal: true,
                tickPositioner: function () {
                    const tickPositions = [];
                    for (let i = 0; i < swingData[metric.key].length; i += 1) {
                        tickPositions.push(i);
                    }
                    return tickPositions;
                },
                labels: {
                    formatter: (tick):string => {
                        return DateTime.fromISO(swingData[metric.key][tick.value].date).toFormat("MM/dd");
                    }
                }
            },
            yAxis: [{
                title: {
                    text: metric.yAxis.label
                },
                labels: {
                    format: `{value} ${metric.unit}`
                },
                plotBands: metric.yAxis.bands ?? [],
                max : metric.yAxis.max ?? null
            }, {
                title : {
                    text: "Total Swings"
                },
                labels: {
                    format: "{value}"
                },
                opposite: true,
                min: 0
            }],
            legend: {
                enabled: true
            },
            plotOptions: {
                line : {
                    lineWidth: 1
                },
                column: {
                    stacking: "normal"
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                shared: true,
                formatter: function() {
                    let tooltip:string = `Total Swings: <b>${getToolTipValue("count", (this as any))}</b><br />`;
                    tooltip += `Average ${metric.label}: <b>${getToolTipValue("avg", (this as any))}</b><br />`;
                    tooltip += `${metric.label} Range: <b>${getToolTipValue("range", (this as any))}</b><br />`;

                    if(withEquipment) {
                        tooltip += getToolTipEquipment(this);
                    }

                    tooltip += `Date: <b>${(this.points![0].point as any).date}</b>`;
                    return tooltip;
                }
            },
            series: [
                ...getSeries()
            ],
            credits: {
                enabled: false
            },
            accessibility : {
                enabled: false
            }
        }

        if(subtitle) {
            chartData.subtitle = {
                text: subtitle,
                align: 'left'
            }
        }

        Highcharts.chart(highchartsRef.current!, chartData);
    }

    const getToolTipValue = (aggType:string, point:any) => {
        let value:number = 0;
        let high:number = 0;
        let low:number = 0;
        let formattedValue:string = "";
        point.points.forEach((_point:any) => {
            if(_point.point.aggType === aggType) {
                value += _point.y;
                high = _point.point.high;
                low = _point.point.low;
            }
        });

        formattedValue = (() => {
            if(aggType === "range") {
                return `${low}${metric.unit} - ${high}${metric.unit}`;
            } else if(aggType === "count") {
                return `${value}`;
            } else if(aggType === "avg") {
                return `${Number(value).toFixed(2)}${metric.unit}`;
            }

            return "";
        })();

        return formattedValue;
    }

    const getToolTipEquipment = (point:any) => {
        let tooltip:string = "";

        point.points.forEach((_point:any) => {
            if(_point.point.aggLabel === "Equipment Swings") {
                if(!_point.point.y) return;
                tooltip += `&nbsp;&nbsp;${_point.series.name}: <b>${_point.point.y} @ ${formatNumber(_point.point.avg)}${metric.unit}</b><br />`;
            }
        });

        return tooltip;
    }

    const getSeries = ():any[] => {
        const _series:any = [];
        const _equipment:string[] = [];
        if(withEquipment) {
            swingData[metric.key].forEach((bucket:any) => {
                bucket.equipment.forEach((equipment:any) => {
                    _equipment.push(equipment.name);
                });
            });
        }
        const equipmentList:string[] = [...new Set(_equipment)];
        const colorDataPoints = [
            ...["avg", "range", "count"],
            ...equipmentList
        ]

        if(withEquipment) {
            equipmentList.forEach((equipment:string) => {
                _series.push({
                    "type" : "column",
                    "name" : equipment,
                    "id" : `${metric.key}-${equipment}`,
                    "color" : graph.colorAssignments(colorDataPoints, metric.colors.equipment)[equipment],
                    "borderColor" : "var(--color-surface-600)",
                    "yAxis" : 1,
                    "data" : swingData[metric.key].map((bucket:any, iteration:number) => {
                        const match:any = bucket.equipment.find((equip:any) => equipment === equip.name);
                        return {
                            "x" : iteration,
                            "y" : match ? match.count : 0,
                            "aggLabel" : "Equipment Swings",
                            "aggType" : "count",
                            "avg" : match ? match.avg : 0,
                            "date" : bucket.date
                        }
                    })
                });
            });
        } else {
            _series.push({
                "type" : "column",
                "name" : `Total Swings`,
                "id" : `${metric.key}-count`,
                "color" : graph.colorAssignments(colorDataPoints, metric.colors.environment).count,
                "borderColor" : "var(--color-surface-600)",
                "yAxis" : 1,
                "data" : swingData[metric.key].map((bucket:any, iteration:number) => {
                    return {
                        "x" : iteration,
                        "y" : bucket.count,
                        "aggLabel" : "Total Swings",
                        "aggType" : "count",
                        "date" : bucket.date
                    }
                })
            });
        }

        _series.push({
            "type" : "spline",
            "name" : `Average ${metric.label}`,
            "id" : `${metric.key}-avg`,
            "color" : graph.colorAssignments(colorDataPoints, metric.colors.environment).avg,
            "borderColor" : "var(--color-surface-600)",
            "yAxis" : 0,
            "data" : swingData[metric.key].map((bucket:any, iteration:number) => {
                return {
                    "x" : iteration,
                    "y" : bucket.avg,
                    "aggLabel" : "Average",
                    "aggType" : "avg",
                    "date" : bucket.date
                }
            })
        });

        _series.push({
            "type" : "arearange",
            "name" : `Range ${metric.label}`,
            "id" : `${metric.key}-range`,
            "color" : graph.colorAssignments(colorDataPoints, metric.colors.environment).range,
            "borderColor" : "var(--color-surface-600)",
            "yAxis" : 0,
            "lineWidth" : 1,
            "linkedTo" : ":previous",
            "fillOpacity" : 0.2,
            "marker" : {
                "enabled" : false
            },
            "data" : swingData[metric.key].map((bucket:any, iteration:number) => {
                return {
                    "x" : iteration,
                    "low" : Number(bucket.min),
                    "high" : Number(bucket.max),
                    "aggLabel" : "Range",
                    "aggType" : "range",
                    "date" : bucket.date
                }
            })
        });

        return _series;
    }

    const EquipmentSplits = () => {
        if(!showEquipmentSplits) {
            return <></>
        }

        return (
            <div className="graph-actions button-group">
                <button
                    className={withEquipment ? "primary" : ""}
                    onClick={() => {
                        setWithEquipment(withEquipment ? false : true);
                    }}
                >
                    <span className={`fa-regular fa-square${withEquipment ? "-check" : ""} equipment-splits-icon`} />Bat Splits
                </button>
            </div>
        )
    }

    if(!swingData) {
        return (
            <div className={`graph-container ${!selected ? "hide" : ""}`}>
                <div className={`graph-loader`} />
            </div>
        );
    }

    return (
        <div className={`graph-container ${!selected ? "hide" : ""}`}>
            <div ref={highchartsRef}></div>
            <EquipmentSplits />
            <div className={`graph-loader ${swingDataLoading ? "show" : "hide"}`} />
        </div>
    );
}


export default SwingStatsByDay;