
import React, { useState, useEffect, useRef } from 'react';
import Highcharts, { onTickAfterGetPosition } from 'highcharts';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import graph from './helper/graph';
import { useOutsideBlur, getNestedValue } from './helper/utils';
import { useSession } from './hooks/session';

interface ifSwingsByTime {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account?:any;
    metric:any;
    selected:boolean;
    visible:boolean;
    subtitle?:string;
}
const SwingsByTime = ({ dateStart, dateEnd, equipment, environment, account, metric, selected, visible, subtitle }:ifSwingsByTime) => {
    const [swingData, setSwingData] = useState<any>(null);
    const [swingDataLoading, setSwingDataLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<string>("environment");
    const [categoryTrends, setCategoryTrends] = useState<any>({});
    const { getAccessToken, getTokenAppClaim } = useSession();
    const highchartsRef = useRef(null);
    const trendsMenuRef = useRef(null);
    const defaultFields = [
        "context.environment",
        "created",
        "equipment.name",
        metric.field
    ];

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd && visible) {
            setSwingDataLoading(true);
            getData();
        }
    }, [dateStart, dateEnd, equipment.length, environment.length, account, visible]);

    useEffect(() => {
        if(dateStart && dateEnd) {
            getTrendCategories();
        }
    }, [category, swingData]);

    useEffect(() => {
        if(swingData) {
            setSwingDataLoading(false);
            renderChart();
        }
    }, [categoryTrends]);

    const getMetric = (swing:any):number => {
        return Number(getNestedValue(swing, metric.field));
    }

    const getData = async () => {
        const fields = [
            ...defaultFields
        ];
        const appMetadata:any = getTokenAppClaim("app_metadata");

        // add the user (account) field to the response fields list
        // if the logged in user has access to multiple accounts
        if(Array.isArray(appMetadata.blast_id)) {
            if(appMetadata.blast_id.length > 1) {
                fields.push("user.id");
            }
        }

        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/swings`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account,
                "fields" : fields
            }
        });

        // set the swingData and also create an incrementing value for each (x axis)
        setSwingData(swingsResponse.map((swing:any, iteration:number) => {
            swing.iteration = iteration;
            return swing;
        }));
    }

    const getTrendCategories = () => {
        if(!swingData) {
            return;
        }

        if(category == "equipment") {
            const _equipment:string[] = Array.from(new Set(swingData.map((swing:any) => {
                return swing.equipment.name;
            })));

            const envTrends:any = {
                "All" : {
                    "selected" : true,
                    "iteration" : 0
                }
            }

            _equipment.sort((a, b) => a.localeCompare(b)).forEach((__equipment:string, iteration:number) => {
                envTrends[__equipment] = {
                    "selected" : false,
                    "iteration" : iteration + 1
                }
            });

            setCategoryTrends(envTrends);
        } else if(category == "environment") {
            const environments:string[] = Array.from(new Set(swingData.map((swing:any) => {
                return swing.context.environment;
            })));

            const envTrends:any = {
                "All" : {
                    "selected" : true,
                    "iteration" : 0
                }
            }

            environments.sort((a, b) => a.localeCompare(b)).forEach((environment:string, iteration:number) => {
                envTrends[environment] = {
                    "selected" : false,
                    "iteration" : iteration + 1
                }
            });

            setCategoryTrends(envTrends);
        }
    }

    const categoryTrendLines = ():any[] => {
        const _trendLines:any[] = [];

        Object.keys(categoryTrends).forEach((_category:string, iteration:number) => {
            if(categoryTrends[_category].selected) {
                _trendLines.push({
                    type: 'line',
                    name: `${_category} Trend`,
                    data: (() => {
                        let _swings:any[] = [];

                        if(_category == "All") {
                            _swings = [...swingData];
                        } else if(category == "equipment") {
                            _swings = swingData.filter((swing:any) => swing.equipment.name == _category);
                        }  else if(category == "environment") {
                            _swings = swingData.filter((swing:any) => swing.context.environment == _category);
                        }

                        return graph.trendLine(_swings.map((swing:any, iteration:number) => {
                            return [iteration, getMetric(swing)];
                        }), swingData.length);
                    })(),
                    color: graph.colorTrendLine(iteration),
                    marker: {
                        enabled: false
                    },
                    states: {
                        hover: {
                            lineWidth: 0
                        }
                    },
                    enableMouseTracking: false
                });
            }
        });

        return _trendLines;
    }

    const renderChart = ():void => {
        const chartData:Highcharts.Options = {
            chart: {
                type: 'scatter',
                animation: false
            },
            title: {
                text: `${metric.label} Charted by Date`,
                align: 'left'
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                type: 'category',
                ordinal: true,
                tickPositioner: function () {
                    const tickPositions = [];
                    // Set a tick for every 100 data points
                    for (let i = 0; i < swingData.length; i += graph.tickSpacing(swingData.length)) {
                        tickPositions.push(i);
                    }
                    if(tickPositions[tickPositions.length - 1] != (swingData.length -1)) {
                        tickPositions.push(swingData.length - 1); // don't clip the graph
                    }
                    return tickPositions;
                },
                labels: {
                    formatter: (tick):string => {
                        return DateTime.fromISO(swingData[tick.value].created).toFormat("MM/dd");
                    }
                }
            },
            yAxis: {
                title: {
                    text: metric.yAxis.label
                },
                labels: {
                    format: `{value} ${metric.unit}`
                },
                plotBands: metric.yAxis.bands ?? [],
                max : metric.yAxis.max ?? null
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 2.5,
                        symbol: 'circle',
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    jitter: {
                        x: 0.005
                    }
                },
                line : {
                    lineWidth: 1
                },
                series: {
                    animation: false
                }
            },
            tooltip: {
                formatter: function() {
                    let tooltip:string = `${metric.label}: <b>${getMetric(swingData[this.x!])}${metric.unit}</b><br />`
                    tooltip += `Bat: <b>${swingData[this.x!].equipment.name}</b><br />`;
                    tooltip += `Environment: <b>${swingData[this.x!].context.environment}</b><br />`;
                    tooltip += `Date: <b>${DateTime.fromISO(swingData[this.x!].created).toFormat("yyyy-MM-dd HH:mm")}</b>`;
                    return tooltip;
                }
            },
            series: [
                ...getSeries(),
                ...categoryTrendLines()
            ],
            credits: {
                enabled: false
            },
            accessibility : {
                enabled: false
            }
        }

        if(subtitle) {
            chartData.subtitle = {
                text: subtitle,
                align: 'left'
            }
        }

        Highcharts.chart(highchartsRef.current!, chartData);
    }

    const getSeries = ():any[] => {
        if(category == "environment") {
            const environments:string[] = Array.from(new Set(swingData.map((swing:any) => {
                return swing.context.environment;
            })));
            const environmentColors = graph.colorAssignments(environments, metric.colors.environment);
            return environments.map((_environment:string) => {
                return {
                    "name" : _environment,
                    "id" : `${metric}-${_environment}`,
                    "marker" : {
                        "symbol" : "circle"
                    },
                    "type" : "scatter",
                    "color" : environmentColors[_environment],
                    "data" : swingData.filter((swing:any) => swing.context.environment == _environment).map((swing:any) => {
                        return [
                            swing.iteration,
                            getMetric(swing)
                        ];
                    })
                }
            });
        } else if(category == "equipment") {
            const _equipment:string[] = Array.from(new Set(swingData.map((swing:any) => {
                return swing.equipment.name;
            })));
            const equipmentColors = graph.colorAssignments(_equipment, metric.colors.equipment);
            return _equipment.map((__equipment:string) => {
                return {
                    "name" : __equipment,
                    "id" : `{${metric}}-${__equipment}`,
                    "marker" : {
                        "symbol" : "circle"
                    },
                    "type" : "scatter",
                    "color" : equipmentColors[__equipment],
                    "data" : swingData.filter((swing:any) => swing.equipment.name == __equipment).map((swing:any) => {
                        return [
                            swing.iteration,
                            getMetric(swing)
                        ];
                    })
                }
            });
        }

        return [];
    }

    if(!swingData) {
        return (
            <div className={`graph-container ${!selected ? "hide" : ""}`}>
                <div className={`graph-loader`} />
            </div>
        );
    }

    return (
        <div className={`graph-container ${!selected ? "hide" : ""}`}>
            <div ref={highchartsRef}></div>
            <div ref={trendsMenuRef} className="graph-actions button-group">
                <button
                    className={category == "equipment" ? "primary" : ""}
                    onClick={() => {
                        setCategory("equipment");
                    }}
                >Bats</button>
                <button
                    className={category == "environment" ? "primary" : ""}
                    onClick={() => {
                        setCategory("environment");
                    }}    
                >Environments</button>
                <TrendsButton categoryTrends={categoryTrends} trendsMenuRef={trendsMenuRef} setCategoryTrends={setCategoryTrends} />
            </div>
            <div className={`graph-loader ${swingDataLoading ? "show" : "hide"}`} />
        </div>
    );
}

interface ifTrendsButton {
    categoryTrends:any;
    trendsMenuRef:any;
    setCategoryTrends:Function;
}
const TrendsButton = ({ categoryTrends, trendsMenuRef, setCategoryTrends }:ifTrendsButton) => {
    const [trendsMenuOpen, setTrendsMenuOpen] = useState<boolean>(false);
    const [updateCount, setUpdateCount] = useState<number>(0);

    useOutsideBlur(trendsMenuRef, () => { setTrendsMenuOpen(false);});

    return (
        <>
            <button className="graph-trends" onClick={(e) => {
                setTrendsMenuOpen(trendsMenuOpen ? false : true);
                e.stopPropagation();
            }}>
                Trends
                <i className={`fa-solid fa-caret-${trendsMenuOpen ? "up" : "down"}`} />
            </button>
            <div className={`trends-dropdown ${trendsMenuOpen ? "show" : "hide"}`}>
                <ul>
                    {
                        Object.keys(categoryTrends).map((category:string) => {
                            return (
                                <li
                                    key={category}
                                    onClick={(e) => {
                                        setCategoryTrends({
                                            ...categoryTrends,
                                            ...{
                                                [category] : {
                                                    "selected" : categoryTrends[category].selected ? false : true,
                                                    "iteration" : categoryTrends[category].iteration
                                                }
                                            }
                                        });
                                        setUpdateCount(updateCount + 1);
                                    }}
                                >
                                    {category}
                                    <i className={`fa-regular fa-square${categoryTrends[category].selected ? "-check" : ""}`} />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </>
    );
}

export default SwingsByTime;