export default {
    "app" : {
        "name" : "Swingzer User Interface"
    },
    "api" : {
        "ssl" : false,
        "host" : "",//"dev.localhost:3000", // leave blank to use the same host for both API and UI
        "port" : null,
        "root" : "/api/v1"
    },
    "session" : {
        "expire_time" : (60 * 60 * 10), // 10 hours in seconds, this is configured within IDM
        "warn_time" : (5 * 60), // amount of warning to give the user before their session ends
        "share_ttl" : (24 * 60 * 60) // amount of time (seconds) a shared link will be accessible
    },
    "graph" : {
        "colors1" : [
            "#ff8649",
            "#008ac9",
            "#df72c9",
            "#ff6f7a",
            "#ffa600",
            "#6488db",
            "#a67fdc",
            "#ff69a6"
        ],
        "colors2" : ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
        "colors3" : ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"],
        "colors4" : ["#c23728", "#22a7f0", "#de6e56", "#a7d5ed", "#e2e2e2", "#e1a692", "#63bff0", "#e14b31", "#1984c5"],
        "colors5" : ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"],
        "colors6" : [
            '#1f77b4',
            '#ff7f0e',
            '#2ca02c',
            '#d62728',
            '#9467bd',
            '#8c564b',
            '#e377c2',
            '#7f7f7f',
            '#bcbd22',
            '#17becf',
        ],
        "colors7" : [
            '#4e79a7',
            '#f28e2c',
            '#e15759',
            '#76b7b2',
            '#59a14f',
            '#edc949',
            '#af7aa1',
            '#ff9da7',
            '#9c755f',
            '#bab0ab',
        ],
        "colors8" : [
            '#a6cee3',
            '#1f78b4',
            '#b2df8a',
            '#33a02c',
            '#fb9a99',
            '#e31a1c',
            '#fdbf6f',
            '#ff7f00',
            '#cab2d6',
            '#6a3d9a',
            '#ffff99',
            '#b15928',
        ],
        "trend_colors" : [
            "#ffb500",
            "#bbc10e",
            "#74c345",
            "#00bf74",
            "#00b79f",
            "#00abbf",
            "#009ccd",
            "#008ac9"
        ],
        "trend_color" : "#e3ff00"
    }
};
