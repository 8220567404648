import React, { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

import apiRequest from './helper/ws';
import metrics, { metricKeys } from './metricDefinitions';
import { useSession } from './hooks/session';

import './css/Header.css';

interface ifSwingStatsByTime {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account?:any;
    visible:boolean;
    viewType:string;
    updateHook?:Function;
    refreshKey?:number;
}
const SwingsByTime = ({ dateStart, dateEnd, equipment, environment, account, visible, viewType, updateHook, refreshKey }:ifSwingStatsByTime) => {
    const { getAccessToken } = useSession();
    const [swingData, setSwingData] = useState<any>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [metricsList, setMetricsList] = useState<string[]>(
        localStorage.getItem(`${viewType}Stats`)
        ? JSON.parse(localStorage.getItem(`${viewType}Stats`) || '[]')
        : metricKeys.filter((metricKey:string) => metrics[metricKey].statSummary)
    );
    const defaultFields = [
        "context.environment",
        "created",
        "equipment.name"
    ];

    useEffect(() => {
        setMetricsList(
            localStorage.getItem(`${viewType}Stats`)
            ? JSON.parse(localStorage.getItem(`${viewType}Stats`) || '[]')
            : metricKeys.filter((metricKey:string) => metrics[metricKey].statSummary)
        );
    }, [refreshKey]);

    useEffect(() => {
        // ensure required filter values are set
        if(dateStart && dateEnd) {
            getData();
        }
    }, [dateStart, dateEnd, equipment.length, environment.length, account, metricsList]);

    const writeMetricsList = (list:string[]) => {
        localStorage.setItem(`${viewType}Stats`, JSON.stringify(list));
        setMetricsList(list);

        if(updateHook) {
            updateHook();
        }
    }

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/swings/stats`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account,
                "fields" : [
                    ...defaultFields,
                    ...metricsList.map((metricKey:string) => {
                        return metrics[metricKey].field;
                    })
                ],
                "avg" : metricsList.map((metricKey:string) => {
                    return metrics[metricKey].field;
                }),
                "max" : metricsList.map((metricKey:string) => {
                    return metrics[metricKey].field;
                }),
                "min" : metricsList.map((metricKey:string) => {
                    return metrics[metricKey].field;
                }),
                "trends" : metricsList.map((metricKey:string) => {
                    return metrics[metricKey].field;
                })
            }
        });

        setSwingData(swingsResponse);
    }

    const formatNumber = (value:number):string => {
        if(!value) {
            return "0";
        }

        if(value % 1 === 0) {
            return value.toLocaleString();
        }

        return value.toFixed(2);
    }

    interface ifMetric {
        metric:any
    }
    const DeleteStat = ({ metric }:ifMetric) => {
        if(editMode) {
            return (
                <i className="fa-solid fa-trash-can stat-delete" onClick={() => {
                    writeMetricsList(metricsList.filter((metricKey:string) => metricKey !== metric.key));
                }} />
            )
        }
        return <></>
    }

    const Stat = ({ metric }:ifMetric) => {
        return (
            <div className="swing-stat">
                <div className="title">
                    {metric.shortLabel}{metric.unit == "mph" ? ` (${metric.unit})` : ""}
                    <Trend metric={metric} />
                    <DeleteStat metric={metric} />
                </div>
                <div className="content">
                    <Min metric={metric} />
                    <Average metric={metric} />
                    <Max metric={metric} />
                </div>
            </div>
        );
    }

    interface ifDailyTrends {
        trends:any;
    }
    const DailyTrend = ({ trends }:ifDailyTrends) => {
        if(trends.start) {
            if(trends.start > trends.end) {
                return <i className="fa-solid fa-arrow-down" />
            } else {
                return <i className="fa-solid fa-arrow-up" />
            }
        }
        return <></>
    }

    const Trend = ({ metric }:ifMetric) => {
        if(swingData.trends[metric.key]) {
            if(swingData.trends[metric.key].start > swingData.trends[metric.key].end) {
                return <i className="fa-solid fa-arrow-down" />
            } else {
                return <i className="fa-solid fa-arrow-up" />
            }
        }
        return <></>
    }

    const Average = ({ metric }:ifMetric) => {
        return (
            <div className="stat-item primary">
                <div className="stat">{formatNumber(swingData.avg[metric.key])}{metric.unit != "mph" ? metric.unit : ""}</div>
                <div className="stat-label">Average</div>
            </div>
        )
    }

    const Max = ({ metric }:ifMetric) => {
        return (
            <div className="stat-item">
                <div className="stat">{formatNumber(swingData.max[metric.key])}{metric.unit != "mph" ? metric.unit : ""}</div>
                <div className="stat-label small">Max</div>
            </div>
        )
    }

    const Min = ({ metric }:ifMetric) => {
        return (
            <div className="stat-item">
                <div className="stat">{formatNumber(swingData.min[metric.key])}{metric.unit != "mph" ? metric.unit : ""}</div>
                <div className="stat-label small">Min</div>
            </div>
        )
    }

    const DailySwings = () => {
        return (
            <div className="swing-stat">
                <div className="title">
                    Daily Swings
                    <DailyTrend trends={swingData.daily_trend} />
                </div>
                <div className="content">
                    <div className="stat-item">
                        <div className="stat">{swingData.daily_count_min}</div>
                        <div className="stat-label small">Min</div>
                    </div>
                    <div className="stat-item primary">
                        <div className="stat">{Math.round(swingData.daily_count_avg)}</div>
                        <div className="stat-label">Average</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat">{swingData.daily_count_max}</div>
                        <div className="stat-label small">Max</div>
                    </div>
                </div>
            </div>
        );
    }

    const TotalSwings = () => {
        return (
            <div className="swing-stat">
                <div className="title">
                    Total Swings
                </div>
                <div className="content">
                    <div className="stat-item">
                        <div className="stat">&nbsp;</div>
                        <div className="stat-label">&nbsp;</div>
                    </div>
                    <div className="stat-item primary">
                        <div className="stat">{formatNumber(swingData.count)}</div>
                        <div className="stat-label">Total</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat">&nbsp;</div>
                        <div className="stat-label">&nbsp;</div>
                    </div>
                </div>
            </div>
        );
    }

    const AddStat = () => {
        return (
            <div className={`swing-stat stat-add ${!editMode ? "hide" : ""}`}>
                <ul>
                    {
                        metricKeys.filter((metricKey:string) => !metricsList.includes(metricKey)).map((metricKey:string) => {
                            return <li key={`metric-stat-add-${metricKey}`} onClick={() => {
                                writeMetricsList([
                                    ...metricsList,
                                    metricKey
                                ]);
                            }}>
                                <i className="fa-solid fa-plus" />
                                {metrics[metricKey].label}
                            </li>
                        })
                    }
                </ul>
            </div>
        )
    }

    const AdminButton = () => {
        return (
            <div className="stat-admin">
                <div className="inner">
                    <button onClick={() => {
                        setEditMode(editMode ? false : true);
                    }}>
                        <i className={`fa-solid fa-${editMode ? "check" : "pen-to-square"}`} />
                    </button>
                </div>
            </div>
        )
    }

    if(!swingData) {
        return <></>
    }

    return (
        <div className={`stats-container ${visible ? "" : "hide"}`}>
            <TotalSwings />
            <DailySwings />
            {
                metricsList.map((metricKey:string) => {
                    return <Stat key={metricKey} metric={metrics[metricKey]} />
                })
            }
            <AddStat />
            <AdminButton />
        </div>
    );
}

export default SwingsByTime;