import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { DateTime } from 'luxon';

import metrics, { metricKeys } from './metricDefinitions';
import { getNestedValue } from './helper/utils';
import apiRequest from './helper/ws';
import { useSession } from './hooks/session';

import Dropdown from './Dropdown';

import './fa/css/all.css';
import './css/App.css';
import './css/Metrics.css';
import { exec } from 'child_process';

interface ifSwingsList {
    dateStart:string|null;
    dateEnd:string|null;
    equipment:string[];
    environment:string[];
    account:any;
    visible:boolean;
    viewType?:string;
    refreshKey?:number;
    updateHook?:Function;
    equipmentList:string[];
    environmentList:string[];
}
const SwingsList = ({ dateStart, dateEnd, equipment, environment, account, visible, viewType, refreshKey, updateHook, equipmentList, environmentList }:ifSwingsList) => {
    const [swingData, setSwingData] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [sortBy, setSortBy] = useState<string|null>(null);
    const [sortDir, setSortDir] = useState<string>("asc");
    const [totalSwings, setTotalSwings] = useState<number>(0);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [selectedSwings, setSelectedSwings] = useState<string[]>([]);
    const { getAccessToken, checkPermission } = useSession();
    const documentsPerPage = 100;
    const defaultFields = [
        ...[
            "context.environment",
            "created",
            "equipment.name"
        ],
        ...metricKeys.map((metricKey:string) => {
            return metrics[metricKey].field;
        })
    ];

    useEffect(() => {
        if(dateStart && dateEnd && visible) {
            getData();
        }
        setSelectAll(false);
        setSelectedSwings([]);
    }, [dateStart, dateEnd, equipment.length, environment.length, account, sortBy, sortDir, visible, page]);

    const getData = async () => {
        const swingsResponse = await apiRequest({
            "method" : "GET",
            "path" : `/swings`,
            "accessToken" : await getAccessToken(),
            "params" : {
                "date_start" : DateTime.fromISO(`${dateStart}T00:00:00`).toUTC().toISO({includeOffset:true}),
                "date_end" : DateTime.fromISO(`${dateEnd}T23:59:59`).toUTC().toISO({includeOffset:true}),
                "equipment" : equipment.join(","),
                "environment" : environment.join(","),
                "account" : account.blast_id ?? null,
                "fields" : defaultFields,
                "sort" : sortBy,
                "sort_dir" : sortDir,
                "size" : documentsPerPage,
                "from" : (page * documentsPerPage)
            },
            "includeMeta" : true
        });

        // set the swingData and also create an incrementing value for each (x axis)
        setSwingData(swingsResponse.response.map((swing:any, iteration:number) => {
            swing.iteration = iteration;
            return swing;
        }));
        setTotalSwings(swingsResponse.hits);
    }

    const addSwing = (swingId:string) => {
        if(!selectedSwings.includes(swingId)) {
            setSelectedSwings([
                ...selectedSwings,
                swingId
            ]);
        }
    }

    const removeSwing = (swingId:string) => {
        if(selectedSwings.includes(swingId)) {
            setSelectedSwings(selectedSwings.filter((id:string) => id !== swingId ));
        }
    }

    const getSelectedEquipment = ():string => {
        let selectedEquipment:string[] = [];

        selectedSwings.forEach((swingId:string) => {
            const _equip:string = swingData.find((swing:any) => swing.id === swingId).equipment.name;

            if(!selectedEquipment.includes(_equip)) {
                selectedEquipment.push(_equip);
            }
        });

        if(selectedEquipment.length > 1) {
            return "";
        } else if(selectedEquipment.length === 1) {
            return selectedEquipment[0];
        } else {
            return "";
        }
    }

    const getSelectedEnvironment = ():string => {
        let selectedEnvironment:string[] = [];

        selectedSwings.forEach((swingId:string) => {
            const _env:string = swingData.find((swing:any) => swing.id === swingId).context.environment;

            if(!selectedEnvironment.includes(_env)) {
                selectedEnvironment.push(_env);
            }
        });

        if(selectedEnvironment.length > 1) {
            return "";
        } else if(selectedEnvironment.length === 1) {
            return selectedEnvironment[0];
        } else {
            return "";
        }
    }

    const TableHead = () => {
        const EditColumn = () => {
            if(editMode) {
                return <th key="swing-edit-header" className="select-all" onClick={() => {
                    if(selectAll) {
                        setSelectedSwings([]);
                    } else {
                        setSelectedSwings(swingData.map((swing:any) => {
                            return swing.id;
                        }));
                    }

                    setSelectAll(selectAll ? false : true);
                }}>
                    <i className={`fa-regular fa-square${selectAll ? '-check' : ''}`} />
                </th>
            }

            return <></>
        }

        const HeaderValue = ({label, inUse}:{label:string, inUse:boolean}) => {
            const DirectionIcon = () => {
                if(inUse) {
                    return <i className={`fa-solid fa-caret-${sortDir === "asc" ? "up" : "down"}`} />
                }

                return <></>
            }

            return <div className="swing-header-container">
                <div className="content">
                    {label}
                </div>
                <div className="direction">
                    <DirectionIcon />
                </div>
            </div>
        }

        const executeSort = (field:string) => {
            if(field === sortBy) {
                // field is already being sorted, reverse the sort direction
                setSortDir(sortDir === "asc" ? "desc" : "asc");
                setPage(0);
            } else {
                // new field being sorted
                // set new sortBy and reset other state values
                setSortBy(field);
                setSortDir("asc");
                setPage(0);
            }
        }

        return (
            <thead>
                <tr>
                    <EditColumn />
                    <th
                        key="swing-header-environment"
                        className="swing-list-header"
                        onClick={() => {
                            executeSort("context.environment.keyword");
                        }}
                    >
                        <HeaderValue label="Environment" inUse={sortBy === "context.environment.keyword"} />
                    </th>
                    <th
                        key="swing-header-equipment"
                        className="swing-list-header"
                        onClick={() => {
                            executeSort("equipment.name.keyword");
                        }}
                    >
                        <HeaderValue label="Bat" inUse={sortBy === "equipment.name.keyword"} />
                    </th>
                    {
                        metricKeys.filter((metricKey:string) => metrics[metricKey].dataView).map((metricKey:string) => {
                            return <th
                                key={`swing-header-${metricKey}`}
                                className="swing-list-header"
                                onClick={() => {
                                    executeSort(metrics[metricKey].field);
                                }}
                            >
                                <HeaderValue label={metrics[metricKey].shortLabel} inUse={sortBy === metrics[metricKey].field} />
                            </th>
                        })
                    }
                    <th
                        key="swing-header-datetime"
                        className="swing-list-header"
                        onClick={() => {
                            executeSort("created");
                        }}
                    >
                        <HeaderValue label="Datetime" inUse={sortBy === "created"} />
                    </th>
                </tr>
            </thead>
        );
    }

    const SwingRow = ({ swing }:{swing:any}) => {
        const selected:boolean = selectedSwings.includes(swing.id);

        const EditColumn = () => {
            if(editMode) {
                return (
                    <td key={`swing-document-${swing.id}-edit`}>
                        <i className={`fa-regular fa-square${selected ? "-check" : ""}`} />
                    </td>
                );
            }

            return <></>
        }
    
        return (
            <tr className={`${editMode ? 'edit-mode' : ''} ${selected ? "selected" : ""}`} onClick={() => {
                if(editMode) {
                    if(selected) {
                        removeSwing(swing.id);
                    } else {
                        addSwing(swing.id);
                    }
                }
            }}>
                <EditColumn />
                <td key={`swing-document-${swing.id}-environment`}>{swing.context.environment}</td>
                <td key={`swing-document-${swing.id}-equipment`}>{swing.equipment.name}</td>
                {
                    metricKeys.filter((metricKey:string) => metrics[metricKey].dataView).map((metricKey:string) => {
                        return <td key={`swing-document-${swing.id}-${metricKey}`}>{getNestedValue(swing, metrics[metricKey].field)}</td>
                    })
                }
                <td key={`swing-document-${swing.id}-datetime`}>{DateTime.fromISO(swing.created).toFormat("MM/dd/yy HH:mm:ss")}</td>
            </tr>
        );
    }

    const Actions = () => {
        const SaveButton = () => {
            if(!editMode) {
                return <></>
            }

            return (
                <button className="primary" onClick={() => {
                    if(selectedSwings.length > 0) {
                        setShowEdit(true);
                    }
                }}>
                    <i className="fa-regular fa-floppy-disk" />
                </button>
            )
        }

        const DeleteButton = () => {
            if(!editMode) {
                return <></>
            }

            return (
                <button onClick={() => {
                    if(selectedSwings.length > 0) {
                        setShowDelete(true);
                    }
                }}>
                    <i className="fa-regular fa-trash-can" />
                </button>
            )
        }

        return (
            <div className="actions-container">
                {(checkPermission("write:swings") || checkPermission("delete:swings")) && <div className="button-group data-view-actions">
                    <button onClick={() => {
                        if(editMode) {
                            setSelectedSwings([]);
                        }

                        setEditMode(editMode ? false : true);
                    }}>
                        <i className={`${editMode ? 'fa-solid fa-xmark' : 'fa-regular fa-pen-to-square'}`} />
                    </button>
                    {checkPermission("delete:swings") && <DeleteButton />}
                    {checkPermission("write:swings") && <SaveButton />}
                </div>}
                <div className="data-paging">
                    <div className="button-group right data-view-paging">
                        Viewing {(page * documentsPerPage) + 1} - {swingData.length < documentsPerPage ? (page * documentsPerPage) + swingData.length : (page * documentsPerPage) + documentsPerPage} of {totalSwings.toLocaleString()} Swings&nbsp;&nbsp;&nbsp;&nbsp;
                        <button onClick={() => {
                            setPage(0);
                        }}>
                            <i className="fa-solid fa-angles-left" />
                        </button>
                        <button onClick={() => {
                            if(page < 1) {
                                return;
                            }

                            setPage(page - 1);
                        }}>
                            <i className="fa-solid fa-chevron-left" />
                        </button>
                        <button className="primary">{page + 1}</button>
                        <button onClick={() => {
                            if(page >= Math.floor(totalSwings / documentsPerPage)) {
                                return;
                            }

                            setPage(page + 1);
                        }}>
                            <i className="fa-solid fa-chevron-right" />
                        </button>
                        <button onClick={() => {
                            setPage(Math.floor(totalSwings / documentsPerPage));
                        }}>
                            <i className="fa-solid fa-angles-right" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const EditModal = () => {
        const [equipment, setEquipment] = useState<string>(getSelectedEquipment());
        const [environment, setEnvironment] = useState<string>(getSelectedEnvironment());
        const [showConfirm, setShowConfirm] = useState<boolean>(false);
        const [showLoading, setShowLoading] = useState<boolean>(false);

        const saveSwings = async () => {
            setShowLoading(true);

            const swingsResponse = await apiRequest({
                "method" : "PATCH",
                "path" : `/swings`,
                "accessToken" : await getAccessToken(),
                "data" : {
                    "swing_ids" : selectedSwings,
                    "equipment" : equipment,
                    "environment" : environment
                }
            });

            // pause for two seconds to allow elastic to update
            setTimeout(async () => {
                await getData();
                setShowEdit(false);
                setSelectAll(false);
                setSelectedSwings([]);
            }, 2000);
        }

        if(showLoading) {
            return (
                <div className={`app-loader ${!showLoading ? "hide" : ""}`}>
                    <div className="loader-container">
                        <div className="loading-text">Updating Swings</div>
                        <i className="fa-solid fa-spinner rotate" />
                    </div>
                </div>
            );
        }

        if(showConfirm) {
            return (
                <div className={`data-modal ${!showEdit ? "hide" : ""}`}>
                    <div className="modal-container warning">
                        <span className="confirm-warning">
                            <h3>Warning</h3>
                            You are about to update {selectedSwings.length} swing{selectedSwings.length > 1 ? "s" : ""}, this action cannot be undone.  Please confirm the update.
                        </span>
                        <div className="button-group right warning">
                            <button className="primary" onClick={() => {
                                saveSwings();
                            }}>
                                Confirm Update
                            </button>
                            <button onClick={() => {
                                setShowConfirm(false);
                            }}>
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={`data-modal ${!showEdit ? "hide" : ""}`}>
                <div className="modal-container">
                    <span className="info-message">Update {selectedSwings.length} Selected Swings</span>
                    <Dropdown
                        options={equipmentList.map((equip:string) => {
                            return {
                                "key" : equip,
                                "value" : equip
                            }
                        })}
                        selected={equipment}
                        setSelected={setEquipment}
                        placeholder="Bat"
                        className="dropdown"
                    />
                    <Dropdown
                        options={environmentList.map((env:string) => {
                            return {
                                "key" : env,
                                "value" : env
                            }
                        })}
                        selected={environment}
                        setSelected={setEnvironment}
                        placeholder="Environment"
                        className="dropdown"
                    />
                    <div className="button-group right">
                        <button className="primary" onClick={() => {
                            setShowConfirm(true);
                        }}>
                            Update Swings
                        </button>
                        <button onClick={() => {
                            setShowEdit(false);
                        }}>
                            <i className="fa-solid fa-xmark" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const DeleteModal = () => {
        const [showLoading, setShowLoading] = useState<boolean>(false);

        const deleteSwings = async () => {
            setShowLoading(true);

            const swingsResponse = await apiRequest({
                "method" : "DELETE",
                "path" : `/swings`,
                "accessToken" : await getAccessToken(),
                "data" : {
                    "swing_ids" : selectedSwings
                }
            });

            // pause for two seconds to allow elastic to update
            setTimeout(async () => {
                await getData();
                setShowDelete(false);
                setSelectAll(false);
                setSelectedSwings([]);
            }, 2000);
        }

        if(showLoading) {
            return (
                <div className={`app-loader ${!showLoading ? "hide" : ""}`}>
                    <div className="loader-container">
                        <div className="loading-text">Deleting Swings</div>
                        <i className="fa-solid fa-spinner rotate" />
                    </div>
                </div>
            );
        }

        return (
            <div className={`data-modal ${!showDelete ? "hide" : ""}`}>
                <div className="modal-container error">
                    <span className="confirm-error">
                        <h3>Warning</h3>
                        You are about to delete {selectedSwings.length} swing{selectedSwings.length > 1 ? "s" : ""}, this action cannot be undone.<br /><br />Please confirm the delete operation.
                    </span>
                    <div className="button-group right error">
                        <button className="primary" onClick={() => {
                            deleteSwings();
                        }}>
                            Confirm Delete
                        </button>
                        <button onClick={() => {
                            setShowDelete(false);
                        }}>
                            <i className="fa-solid fa-xmark" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    if(!swingData) {
        return <div className={`route-container ${visible ? "show" : "hide"}`} />
    }

    return (
        <div className={`route-container ${visible ? "show" : "hide"}`}>
            <Actions />
            <table className="swing-data">
                <TableHead />
                <tbody>
                    {
                        swingData.map((swing:any) => {
                            return <SwingRow key={`swing-row-${swing.id}`} swing={swing} />
                        })
                    }
                </tbody>
            </table>
            <EditModal />
            <DeleteModal />
        </div>
    );
}

export default SwingsList;